import React from 'react'

function Footer() {
  return (
    <div className="footer_container">
      <div className='footer_wrapper'>
        <div><span id='content'><span id='title'>powered by : </span>Advertise IQ</span></div>
      </div>
    </div>
  )
}

export default Footer