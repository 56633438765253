import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./locales/en.json";
import translationHi from "./locales/hi.json";
import translationGu from "./locales/gu.json";
import translationZh from "./locales/zh.json";
import translationFR from "./locales/fr.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEn,
      },
      hi: {
        translation: translationHi,
      },
      gu: {
        translation: translationGu,
      },
      zh: {
        translation: translationZh,
      },
      fr: {
        translation: translationFR,
      },
    },
  });

export default i18n;
